import React from 'react'
import engineerImage from '../assets/images/service/engineer.jpg'
import consultantImage from '../assets/images/service/consultant.jpg'
import productImage from '../assets/images/service.jpg'

export default () => [
  {
    "image": engineerImage,
    "imageAlt": "エンジニア",
    "to": './it',
    "title": "ITコンサルティングサービス",
    "content": ["要件定義・設計・開発・テスト・運用等のシステム開発の全フェーズをサポートいたします", <br />,
    ]
  },
  {
    "image": consultantImage,
    "imageAlt": "コンサルタント",
    "to": './consulting',
    "title": "経営・業務コンサルティングサービス",
    "content": ["事業戦略・経営戦略策定に伴走・サポートいたします。", <br />,
      "また、豊富なデジタル化・DX推進の経験をもとに、戦略策定からロードマップ策定、システム・データアーキテクチャの設計等をご支援いたします。", <br />
    ]
  },
  {
    "image": productImage,
    "imageAlt": "プロダクト",
    "to": './product',
    "title": "プロダクト",
    "content": ["世の中を便利にする自社プロダクトを作成しています", <br />
    ]
  },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "3",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "4",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "5",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "6",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "7",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "8",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "9",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "10",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "11",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "12",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "13",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
  // {
  //   "image": vlusImage,
  //   "imageAlt": "vlus",
  //   "to": null,
  //   "title": "14",
  //   "content": ["弊社CEOの松野貞之がOSS脆弱性検知ツールのVulsのメインコミッターの一員となりました", <br />,
  //     "コミッターとは、特定のリポジトリに対して、Write権限(Push権限)を持つことを意味しています", <br />,
  //     "VulsはFuture株式会社の神戸康多氏が開発公開したOSSであり、Golangで書かれた脆弱性検知ツールです", <br />,
  //     "現在のGithubスター数は6000を超え、国産OSSソフトウェアでは最大級のリポジトリとなっています", <br />,
  //   ]
  // },
]
